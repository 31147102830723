@tailwind base;
@tailwind components;
@tailwind utilities;

.containerx {
  @apply border shadow-lg p-2 bg-gray-100;
}
/* #d9f9e3 */
body {
  margin: 0;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Segoe UI", "Roboto",
    "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.site-layout .site-layout-background {
  background: white;
}
.ant-layout-content {
  padding: 0px 5px !important;
  margin-top: 64px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: white;
  /* border-bottom: 2px solid #20B34E; */
}

.ant-menu {
  color: white;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
  color: white;
  background-color: #00bfff;
}
.ant-menu-vertical.ant-menu-sub:not([class*="-active"]),
.ant-menu-vertical-left.ant-menu-sub:not([class*="-active"]),
.ant-menu-vertical-right.ant-menu-sub:not([class*="-active"]) {
  background-color: #00bfff;
}

.ant-layout-header {
  background: #00bfff;
}

.ant-menu {
  background: #00bfff;
}
